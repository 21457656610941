<template>
	<component
		:is="getWrapperComponent"
		v-bind="getWrapperProps"
		data-component="app-logo"
		:data-size="size"
	>
		<img
			:src="getSrc"
			:key="getSrc"
			alt="Bridge128"
		/>
	</component>
</template>

<script>

	import routeParams from '@/mixins/routeParams';

	export default {
		mixins: [routeParams],
		props: {
			linkToHome: {
				type:Boolean,
				default: true
			},
			linkToLesson: {
				type:Boolean,
				default: false
			},
			size: {
				type: String,
				default: 'md'
			},
			colour: {
				type: String,
				default: 'colour'
			}
		},
		computed: {
			getWrapperComponent () {
				if (this.linkToHome ||
						this.linkToLesson) {
					return 'router-link';
				}
				return 'div';
			},
			getWrapperProps () {
				if (!this.linkToHome &&
						!this.linkToLesson) {
					return false;
				}
				if (this.linkToHome) {
					return {
						to: '/'
					};
				}
				if (this.getContext === 'teaching' ||
						this.getContext === 'admin') {
					return {
						to: `/${this.getContextSlug}/courses/${this.getCourseId}/lessons/${this.getLessonId}`
					};
				}
				return {
					to: `/my-courses/${this.getCourseId}/lessons/${this.getLessonId}`
				};
			},
			getSrc () {
				if (this.colour === 'white')  {
					return require('@/assets/logo-horizontal-white.svg');
				}
				return require('@/assets/logo-horizontal-colour.svg');
			}
		}
	};
</script>

<style lang="scss" scoped>
	[data-component='app-logo'] {
		cursor:pointer;
		user-select:none;
		display:block;
		img {
			display:block;
		}
		&[data-size='md'] {
			img {
				width:rem(210);
			}
		}
		&[data-size='sm'] {
			img {
				width:rem(222);
			}
		}
	}
</style>
